.main {
  height: auto;
  margin-bottom: 3rem;
  margin-top: 3rem;
  padding: 0;
}

.home-content-main {
  /* margin-top: -2.1rem; */
}

.loader {
  width: 30px;
  height: 30px;
  border: 3px solid #4b0082;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*video card*/
.videobox {
  width: 100%;
  height: auto !important;
  position: relative;
}

.videobox-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #00000063;
  z-index: 1000;
}

.videooverlay {
  position: absolute;
  width: 100%;
  top: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}

.videooverlay1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 80%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  color: #ffffff;
  background-color: aqua;
}

.videooverlay h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

.videooverlay h1,
.welcome-taxi {
  font-size: 3.75rem;
  /* padding-bottom: 50px; */
  font-weight: 700;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}

.overlay {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

iframe {
  aspect-ratio: 3/2;
  /* display: none; */
}

.aboutmorebtn1,
.aboutmorebtn {
  /* background: linear-gradient(to right, #4b0082 66%, #000000 100%); */
  background: #fff;
  /* color: #fff; */
  color: indigo;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  width: 12rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
  cursor: pointer;
}

.aboutmorebtn {
  /* background: #fff !important; */
  color: #4b0082;
}

.aboutmorebtn1 {
  /* color: #fff;
  background: linear-gradient(to right, #4b0082 66%, #000000 100%); */
  /* background: linear-gradient(to bottom, #00254a, #004080); */
}

.tabbtn1 {
  border: none;
  border-radius: 25px;
  padding: 12px 30px;
  width: 10rem;
  background: linear-gradient(to right, #4b0082 50%, #6f00bf 100%);
  /* background: linear-gradient(to right, #00254a, #004080); */
  /* background: #6f00bf; */
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}


.googlebtn:hover,
.downloadbtn:hover,
.aboutmorebtn1:hover,
.aboutmorebtn:hover {
  background: linear-gradient(to right, #4b0082 66%, #000000 100%) !important;
  /* background: linear-gradient(to right, #00254a, #004080); */
  color: #fff !important;
}


.serviceBookStyleBtn2 {
  position: absolute !important;
  bottom: 1.8rem !important;
}

.serviceBookStyleBtn3 {
  position: absolute !important;
  bottom: 1.9rem !important;
}

.serviceBookStyleBtn45 {
  position: absolute !important;
  bottom: 1.7rem !important;
}

/* .googlebtn:hover,
.aboutmorebtn1:hover {
  background: #fff;
  color: #4b0082;
} */

.mobileboxdisplay {
  display: none;
}

.listul {
  list-style: none;
  padding: 0px;
}

.listul div {
  display: flex;
  align-items: start;
  gap: 0.2rem;
  margin-bottom: 5px;
}

.listul li {
  /* padding: 5px 0px; */
  color: #fff;
}

.howTitle {
  color: #444444;
  font-size: 2.5rem;
  font-weight: 900;
  font-family: 'Poppins';
}

.card {
  overflow: hidden;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.card:hover {
  background: linear-gradient(#4b0082 66%, #000000 100%);

  .main-text h2,
  .main-text p {
    color: #fff;
  }

  .aboutmorebtn1,
  .img2 {
    background: linear-gradient(#FFFF 100%, #000000 63%);
    color: #4b0082;
  }

  .iconservice {
    color: #4b0082;
  }
}

.main-text h2:hover {
  color: #fff;
}

.img1 img {
  height: 185px;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.img2 {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  width: 75px;
  height: 75px;
  background: linear-gradient(#4b0082 66%, #000000 100%);
  background-repeat: no-repeat;
  margin-top: -35px;
  border-radius: 50%;
}

.img2 img {
  justify-content: center;
  padding: 13px !important;
}

.iconservice {
  font-size: 3rem;
  color: #fff;
}

.card1 {
  overflow: hidden;
  border-radius: 10px;
  border: none;
  width: 100%;
  /* height:100%; */
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.main-text p {
  font-size: 0.9rem;
  padding: 10px;
  color: rgb(90, 89, 89);
  font-family: 'Poppins';
  text-align: left;
}

.main-text {
  text-align: center;
  padding: 10px 0;
}

.main-text h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  margin: 10px;
  font-family: 'Poppins';
  text-align: left;
  color: #4b0082;
}

.card1:hover {
  background: linear-gradient(#4b0082 66%, #000000 100%);

  .car-price,
  .main-text1 p,
  .main-text1 h2 {
    color: #fff;
  }
}

.main-text1 p {
  font-size: 1rem;
  padding: 0px 10px;
  color: #4b0082;
  font-family: 'Poppins';
}

.main-text1 h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 18px;
  margin: 10px;
  font-family: 'Poppins';
  color: #4b0082;
}

.tabbtn {
  border: none;
  border-radius: 25px;
  width: 10rem;
  padding: 12px 30px;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: #4b0082;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}

#leftimageconor {
  position: relative;
}

.rect1 {
  width: 80%;
  height: 14rem;
  background-color: #ff0000;
  border-radius: 10px;
  position: absolute;
  top: -50px;
  left: 10px;
  right: 10px;
  z-index: 1;
}

.rect2 {
  width: 100%;
  height: auto;
  border-radius: 0px;
  position: absolute;
  top: 30px;
  left: 0px;
  background-image: url('../../public/assets/cardright.jfif');
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}

.popularCategoriesCard {
  background-size: cover;
  border-radius: 10px;
  width: 182px;
  height: 182px;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s;
  margin-bottom: 40px;
  margin-top: 1rem;
  background-color: #4b0082;
}

.bluebgcircle {
  width: 12rem;
  height: 12rem;
  background-color: rgb(255, 209, 3);
  border: 2px solid #fff;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
  border-radius: 50%;
}

.popularCategoriesCard p {
  width: fit-content;
  font-size: 16px;
  padding-top: 5px;
  font-weight: lighter;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
}

.popularCategoriesCard1 {
  background-size: cover;
  background-color: #ffffff;
  border-radius: 10px;
  width: 250px;
  height: 250px;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s;
  margin-bottom: 40px;
  margin-top: 1rem;
  padding: 0 !important;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 22px 70px 4px; */
}

.popularCategoriesCard1:hover {
  border: 1px solid #4b0082;
}

.popularCategoriesCard2 {
  background-size: cover;
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  align-items: left;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.1s;
  margin-bottom: 40px;
  overflow: auto;
  margin-top: 1rem;
  box-shadow: (0, 0, 0, 0.35) 0px 5px 15px;
  height: 260px;
}

.bluebgcircle1 {
  width: 4rem;
  height: 4rem;
  background-color: #4b0082;
  /* background-size: 100%; */
  /* background-repeat: no-repeat; */
  /* text-align: center; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.popularCategoriesCard1 h6 {
  width: fit-content;
  font-size: 16px;
  padding-top: 5px;
  font-weight: lighter;
  font-weight: 800;
  padding-left: 10px;
  padding-right: 10px;
  color: #4b0082;
}

.popularCategoriesCard1 p {
  width: fit-content;
  font-size: 12px;
  padding-top: 5px;
  font-weight: lighter;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: rgb(0, 0, 0);
}

.iconfeatues {
  color: #fff;
  font-size: 3rem !important;
}

.features-Con>div h3 {
  /* font-weight: 900; */
}

.features-Con>div p {
  font-size: 15px;
  /* color: #444444; */
}

.footerimageborder1 {
  background-color: blue;
  height: 2.5rem;
  width: 100%;
}

.footerimageborder1 img {
  width: 100%;
  height: 2.5rem;
  object-fit: cover;
}

.input-filed {
  width: 100%;
  background-color: #ffffff;
  border: none;
  outline: none;
  height: 3rem;
  padding: 8px 10px;
  position: relative;
  border: 2px solid #4b0082;
  border-radius: 10px;
  transition: 0.3s ease;
}

.input-filed:hover {
  border: 2px solid #6f00bf;
  background-color: #fafafa;
}

.input-filed:focus {
  border: 2px solid #6f00bf;
  background-color: #edecf3;
}

#textarea {
  width: 100%;
  background-color: #ffffff;
  padding: 8px 10px;
  height: 5rem;
  border-radius: 5px;
  border: 1px solid #c0c0c0;
  position: relative;
  outline: none;
}

.h01 {
  color: #fff;
}

.title {
  font-family: 'Josefin Sans', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.4em;
  margin-top: 6rem;
  color: #000000;
}

.flag-icon {
  width: 100px;
  /* Set the width of the flag */
  height: 60px;
  /* Set the height of the flag */
  /* Set the path to your flag image */
  background-size: cover;
  /* Scale the image to cover the entire container */
  background-position: center;
  /* Center the image within the container */
  background-repeat: no-repeat;
  /* Prevent image repetition if it's larger than the container */
  border: 1px solid #000;
  /* Add a border around the flag */
  border-radius: 5px;
  /* Add rounded corners */
}

.videobox-image img {
  width: 100%;
  height: 77vh;
  object-fit: cover;
  background-color: #00000063;
}

.error_message {
  color: red;
  font-size: 14px;
  margin: 5px;
}

.googlebtn {
  border: none;
  border-radius: 42px;
  width: 16rem;
  padding: 12px 40px;
  /* background: linear-gradient(#FF9900 66%, #ffffff 100%); */
  /* background: linear-gradient(to right, #4b0082 66%, #000000 100%); */
  background: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  /* color: rgb(255, 255, 255); */
  color: indigo;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}

.downloadbtn {
  border: none;
  border-radius: 42px;
  width: 16rem;
  padding: 12px 40px;
  background-color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 700;
  color: #4b0082;
  display: flex;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
}

.downloadbtn:hover {
  /* background: linear-gradient(to right, #4b0082 66%, #000000 100%); */
  /* background: linear-gradient(to right, #00254a, #004080); */
  /* color: #fff; */
}

/*book a taxi*/
.booking-containermain {
  width: 100%;
  background-color: #d7d7d7;
}

.booking-containermain.google-maps-container {
  width: calc(100% - 400px);
  height: calc(100vh - 104px);
  position: absolute;
  top: 104px;
  right: 0;
  z-index: 0;
}

.booking-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.booktapbtn {
  background-color: #fff;
  color: #4b0082;
  border: none;
  padding: 12px 40px;
}

.booktapbtnactive {
  border-bottom: 2px solid #4b0082 !important;
  background-color: #fff;
  color: #4b0082;
  border: none;
  padding: 12px 40px;
}

.form-check-input:checked {
  background-color: #4b0082;
  border-color: #4b0082;
}

/*booking form div*/
.bookingbgimage {
  background-image: url('../../public/assets/Bookingformbg.jpg');
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
}

.featuresbgimage {
  /* background-image: url('../../public/assets/Sydney.jpg');
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat; */
  background: #fff;
  padding: 0 !important;
  /* background: linear-gradient(to right, #4b0082, #4a4a4a); */
}

.chooseusbgimage {
  background-image: url('../../public/assets/aus.png');
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.chooseus {
  backdrop-filter: blur(15px) !important;
}

.Reviewboximage {
  position: relative;
  background-image: url('../../public/assets/Sydney.jpg');
  background-size: 100% 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.blur {
  backdrop-filter: blur(4px);
  width: 100% !important;
}

.mapbox img {
  border-radius: 15px;
  width: 100%;
  height: auto;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.bookingformbox {
  background-color: #e3d9d947;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  height: auto;
}

.form-control {
  width: 50% !important;
}

.input-group-text {
  background-color: #00000063 !important;
  /* border-left: none !important; */
  color: #fff !important;
  width: 36px !important;
}

.input-group input::placeholder {
  color: white;
}

.storeIconMainDiv {
  width: auto !important;
  cursor: pointer !important;
  /* background: #5F6368; */
  border-radius: 25px;
  color: white;
  /* border: 2px solid white !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  gap: 1rem;
}

.storeIconMainDiv strong {
  font-size: 18px;
}

.storeIconMainDiv i {
  font-size: 20px !important;
}

.storeIconMainDiv:hover {
  box-shadow: #4a4a4a !important;
}

.storeIconMainDivImages {
  width: 100% !important;
  height: auto !important;
}

/* If you want to style the placeholder for a specific input, you can use its ID or class name */
/* For example, if your input has a class name of 'form-control', you can do the following: */
.form-control::placeholder {
  color: white;
}

.form-control,
.form-select {
  background-color: #00000063 !important;
}

/*book a ride page*/
/* Create two equal columns that floats next to each other */
.columnbookrideform {
  float: left;
  width: 70%;
  padding: 0px;
}

.signup-link {
  color: #4b0082;
}

.signup-link:hover {
  color: #ffffff;
}

.columnbookrideform1 {
  float: right;
  width: 30%;
  height: 100% !important;
}

#bookridetabbtn {
  width: 100%;
  height: 4rem;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 4px 5px 0 rgba(0, 0, 0, .14);
  border-bottom: solid 1px gainsboro;
  display: flex !important;
  justify-content: start !important;
  overflow-x: auto;
  overflow-y: hidden;
  /* scrollbar-width: thin; */
}

/* #bookridetabbtn::-webkit-scrollbar {
  height: 0.4rem;
}

#bookridetabbtn::-webkit-scrollbar-thumb {
  background-color: #c0bfbf;
  border-radius: 10px;
}

#bookridetabbtn::-webkit-scrollbar-track {
  background-color: gainsboro;
} */

.bookridetabbtn {
  padding: 14px 18px;
  border: none;
  white-space: nowrap;
  background-color: #ffffff;
  outline: none;
  font-size: 1.2rem;
  font-weight: 500;
  flex-grow: 1;
  color: #4b0082;
  border-bottom: 2px solid gainsboro;
}

.bookridetabbtn:hover,
.bookridetabbtnactive:hover {
  background: #f8f6f2;
}

.bookridetabbtn:active,
.bookridetabbtnactive:active {
  /* background: #edecf3; */
  opacity: 0.9;
}

.bookridetabbtnactive {
  padding: 14px 18px;
  border: none;
  white-space: nowrap;
  background-color: #ebebeb;
  outline: none;
  font-size: 1.2rem;
  font-weight: 700;
  flex-grow: 1;
  color: #4b0082;
  border-bottom: 2px solid #4b0082;
}

/* #bookridetabRecent {
  width: 100%;
  height: 100%;

  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 4px 5px 0 rgba(0, 0, 0, .14);
} */

.recenttriptitle {
  font-size: 1.2rem;
  font-weight: 800;
  text-align: left;
}

.recenttrippara {
  text-align: left;
}

.quick-book-panel {
  overflow-y: auto;
  height: 87vh;
  padding: 0px;
  background-color: #fff;
  color: #4a4a4a;
  font-family: "Visby CF";
  font-size: 14px;
  display: grid;
  place-items: center;
}

.empty-recent-list {
  overflow-y: auto;
  position: relative;
  max-height: calc(100vh - 300px);
}

.recent-trips-template {
  font-family: "Visby-CF-Bold";
  font-size: 22px;
  background-color: #4a4a4a;
  padding: 25px 25px 25px 25px;
  color: #fff;
  text-align: left;
}

.arrow-down {
  margin-left: 22px;
  width: 0;
  height: 0;
  border-left: 14px solid rgba(0, 0, 0, 0);
  border-right: 14px solid rgba(0, 0, 0, 0);
  border-top: 18px solid #4a4a4a;
}

.booking-template {
  padding-top: 10px;
  font-weight: 500;
  border-bottom: 8px solid #edecf3;
  text-align: left;
}

.booking-template .passenger-name {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Visby-CF-Bold";
  margin-left: 28px;
}

.passenger-name {
  font-weight: bold;
  color: #4a4a4a;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 22px;
}

.default-brand-color {
  background-color: #4b0082;
}

.booking-template .dot {
  height: 15px;
  width: 15px;
  background-color: #4a4a4a;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 15px 10px 28px;
}

.booking-template .booking-txt {
  position: absolute;
  margin-top: 10px;
}

.booking-template .default-gray-background {
  background-color: #f8f6f2;
}

.btn-quick-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 40px 24px;
}

.btn-quick-login .btn-signup {
  border: 2px solid var(--primary-brand-colour);
  /* background: linear-gradient(to right, #4b0082 66%, #000000 100%); */
  background: linear-gradient(to right, #00254a, #004080);
  color: #fff;
  border-radius: 4px;
  width: 168px;
  height: 50px;
  cursor: pointer;
  -webkit-transition-duration: .4s;
  -o-transition-duration: .4s;
  transition-duration: .4s;
  font-family: "Visby-CF-Bold", Arial;
  font-size: 18px;
  color: #fff;
  -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .15);
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, .15);
  width: 100%;
  text-align: center;
}

.bookingaridenavtab {
  width: 100%;
  height: 2.5rem;
  background-color: #4a4a4a;
  display: flex;
}

.bookingaridenavtableft {
  width: 32%;
  height: 2.5rem;
  display: flex;
  color: #fff;
}

.bookingaridenavtabright {
  width: 68%;
  height: 2.5rem;
  display: flex;
}

.karachimodeledit {
  border: none;
  outline: none;
  background-color: #fff;
  color: #000000;
  padding: 5px;
  width: 15rem;
}

.mapviewtab {
  border: none;
  outline: none;
  padding: 5px;
  width: 12rem;
  color: #fff;
  background-color: transparent;
}

.mapviewtabactive {
  border: none;
  outline: none;
  padding: 5px;
  width: 12rem;
  font-weight: 800;
  color: #fff;
  background-color: transparent;
  border-bottom: 5px solid #4b0082;
}

.Pendingpick-upboxmain {
  content: "";
  display: table;
  clear: both;
  width: 100%;
  height: 100%;
}

.Pendingpick-upboxcol {
  float: left;
  width: 50%;
  padding: 0px;
  height: 400px;
}

/* Clear floats after the columns */
.row-bookrideform {
  /* height: 38.2rem; */
  height: 100%;
  width: 100%;
}

.row-bookrideform:after {
  content: "";
  display: table;
  clear: both;
}

.booking-form-panel {
  width: 30%;
  background-color: #fff;
  position: absolute;
  right: 0px;
  font-family: "Visby CF", Arial;
  height: 87vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.booking-form-panel .booking-form-content {
  width: 100% !important;
}

.booking-form-named-step {
  padding-top: 20px;
  padding-bottom: 20px;
}

.booking-form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  text-align: left;
}

.slider-buttons {
  position: absolute;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
}

.slider-button {
  background-color: #000;
  color: #fff;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  /* margin-bottom: 10px; */
}

.slider-button:hover {
  background-color: #4b0082;
}

.booking-form-named-step {
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-section-header span {
  font-weight: bold;
}

.brand-color {
  color: #4b0082;
}

.form-section-header {
  font-size: 14px;
  color: #4a4a4a;
  margin: 0 auto 4px auto;
  width: 90%;
}

.booking-fields-panel {
  width: 90%;
  position: relative;
  margin: 0 auto 0px auto;
}

.booking-addresswidth {
  width: 100%;
}

.date-time-component {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.price-guarantee-toggle-div {
  margin-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid rgba(0, 0, 0, .23);
  background-color: #f8f6f2;
}

.price-guarantee-toggle {
  width: 90%;
  height: 38px;
  padding-bottom: 0px;
}

.on-account-toggle-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 4px;
  position: relative;
  padding-bottom: 8px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 350px;
  margin: auto;
}

.selected-vehicle-bar-panel {
  width: 100%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  position: relative;
  top: 0px;
  right: 0px;
  border-top: 1px solid rgba(0, 0, 0, .23);
  padding-top: 7px;
}

.vehicle-selector-label-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 90%;
  margin: auto;
  cursor: pointer;
}

.vehicle-selector-label-area>span {
  font-family: "Visby-CF-Medium", Arial;
  font-size: 14px;
  color: #4a4a4a;
}

.service-box-con h1 {
  font-weight: 900;
}

.service-box-con p {
  width: 75%;
  color: #444444;
  /* color: #fff; */
  margin: 0 auto;
}

.service-box-con img {
  width: 70%;
  object-fit: cover;
}

.service-con-card {
  padding: 1.5rem 2rem !important;
  border-radius: 15px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.service-con-card h4 {
  color: black;
}

.service-section-separator {
  width: 100%;
  margin-bottom: 0px;
  margin-top: 0px;
  border: none;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.23);
}

.service-selector {
  height: auto;
  width: 100%;
  margin-top: 0px;
}

.highlight-selected-service {
  background-color: rgba(245, 130, 0, 0.04);
}

.mobile-condition-container {
  width: 100%;
  height: 72px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid #c0bfbf;
}

.mobile-condition-container1 {
  width: 100%;
  height: 72px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid #4b0082;
  background-color: rgba(133, 69, 192, 0.3);
}

.mobile-condition-container:before {
  padding: 0;
  margin: 0;
  display: block;
  content: "";
  width: 25%;
  height: 1.1px;
  background-color: #fff;
  position: relative;
  left: 0;
  bottom: -69px;
}

.mobile-condition-content {
  width: 90%;
  height: 100%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.mobile-condition-content .selected-vehicle-bar-image {
  margin-left: -15px;
}

.selected-vehicle-bar-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.selected-vehicle-bar-image>img {
  width: auto;
  height: 50px;
}

.font_visby {
  font-family: "Visby CF", Arial;
}

.mobile-condition-content .bar-info-row-title-1,
.mobile-condition-content .bar-info-row-1 {
  left: 85px;
}

.mobile-condition-content .bar-info-row-1 {
  left: -11px;
  margin-left: 95px;
}

.bar-info-row-1 {
  font-size: 12px;
  position: absolute;
  top: 40px;
  left: 100px;
}

.font_helvetica {
  font-family: "Helvetica", Arial !important;
}

.bar-info-row-title-1 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  position: absolute;
  top: 15px;
  left: 100px;
}

.service-info-block {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -13px;
  cursor: pointer;
  right: 0;
}

.service-info-icon {
  width: 18px;
  /* left: 11px;
  top: 11px;
  position: relative; */
}

.bar-margin-left {
  padding-left: 16px;
}

.fe-title {
  font-size: 12px;
  margin-bottom: 11px;
  position: absolute;
  /* top: 15px; */
  /* right: 0; */
}

.fe-value-not-available {
  font-size: 14px;
}

.fe-value {
  font-size: 16px;
  position: absolute;
  top: 41px;
  right: 0;
}

.mobile-condition-container:hover {
  border: 2px solid #4b0082;
  background-color: rgba(133, 69, 192, 0.3);
}

/*switch btn*/
.switchbtn {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 40px;
}

.switchbtn input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchslider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4b0082;
  -webkit-transition: .4s;
  transition: .4s;
}

.switchslider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 13px;
  bottom: 2px;
  background-color: #ffffff;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.switchslider {
  background-color: #ebebeb;
}

input:focus+.switchslider {
  box-shadow: 0 0 1px #4b0082;
}

input:checked+.switchslider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.switchslider.round {
  border-radius: 34px;
}

.switchslider.round:before {
  border-radius: 50%;
}

/*choose box*/
.circlechoosebox {
  background: #fff;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}

.chooseicon {
  font-size: 4rem;
  color: #4b0082;
}

.circlechoosebox:hover {
  background: linear-gradient(#4b0082 66%, #000000 100%);

  .chooseicon {
    color: #fff;
  }
}

.appleicon {
  font-size: 2rem;
}

#googleplaystor {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.text-align-left {
  text-align: left !important;
}

.topBoxImage {
  /* background-image: url('../../public/assets/topbox2bg2.jpg'); */
  /* background-image: url('https://img.freepik.com/free-photo/beautiful-travel-road_1127-197.jpg?w=900&t=st=1705586726~exp=1705587326~hmac=8f3c4b2752c3f452d369946ce016e70d4f84772bdcb46fec340ac0c5e3aba1c1'); */
  background-image: url('../../public/assets/HOME-BG.webp');
  /* background-attachment: fixed; */
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  /* background: #5A5A5A; */
  width: 100% !important;
  height: auto;
  /* overflow: hidden;
  position: relative; */
}

.topBoxBlur {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0 !important;
  padding: 4rem;
  padding-top: 3.5rem !important;
  /* backdrop-filter: blur(1px); */
}

.topBoxForm {
  background-color: rgba(192, 192, 192, 0.3);
  padding: 1rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.50) 0px 5px 15px;
}

.loca-line {
  /* position: absolute; */
  height: 2rem;
  width: 2px;
  margin-left: 1.1rem;
  background: #fff;
}

.topBoxfieldsDiv {
  border: 1px solid #ffc107;
  /* border: 2px solid #004080; */
  border-radius: 5px;
  color: white;
  box-shadow: 0px 0px 9px 0px #c0bfbf
}

.BiSolidTaxiFont {
  font-size: 1.4rem;
}

.topboxClass {
  display: flex;
  align-items: center;
}

.topBoxCar {
  margin-top: 9rem;
  text-align: left;
  color: white;
}

.topBoxFields {
  margin-top: 7rem;
}

.topBoxSedanCar {
  /* width: 110%;
  height: 100%; */
  position: absolute;
  top: -9rem;
}

.BiSolidPhoneCallheaderNav {
  font-size: 40px;
  color: #4b0082
}

.mailheadertext {
  font-weight: 600;
}

.textBookrideFont {
  font-size: 1.4rem;
}

.bookformBoxheadingText {
  position: absolute;
  top: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(255, 255, 255);
  display: flex;
  width: 100%;
  justify-content: center;
}

.bookFormBoxbuttonDiv {
  position: absolute;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 15px
}

.bookingFormGoogleplay {
  font-size: 12px;
  margin-left: 5px;
}

.appstoreBookingformDiv {
  text-align: left;
  margin-left: 3px;
}

.appstoreBookingformDivspan {
  font-size: 12px;
}

/* Map Box */
.mapBoxHeaderDiv {
  background: #4b0082;
  /* background: linear-gradient(to bottom, #336699, #66a3cc); */
  border-radius: 15px;
  box-shadow: rgba(38, 57, 77, 0.5) 0px 20px 30px -10px;
  padding: 10px;
}

.mapBoxHeaderVideoPArentDiv {
  height: 100% !important;
}

.mapBoxHeaderVideo {
  width: 100% !important;
  height: 100% !important;
  border-radius: 15px !important
}

.mapBoxHeaderVideoText {
  color: #fff;
  text-align: left;
  font-weight: 900;
}

.mapBoxHeaderVideoTaxiTest {
  color: whitesmoke;
  text-align: left;
}

.mapBoxHeaderVideoParaTest {
  color: #c0c0c0;
  text-align: left;
}

/* Call to action */
.calltoActionBgColor {
  background-color: indigo !important;
  border-radius: 15px;
  overflow: hidden;
}

.calltoActionimgdiv {
  background-color: #c0c0cc !important;
  padding: 1rem !important;
}

.calltoActionimgTextdiv {
  width: 100% !important;
  text-align: left !important;
  margin-left: 1rem !important;
  color: #000 !important;
}

.calltoActionRightDiv {
  background-color: indigo !important;
  padding: 1rem !important;
}

.calltoActionRightDivIcon {
  font-size: 50px !important;
  color: #fff !important;
}

.calltoActionRightDivIconMainDiv {
  color: #fff !important;
}

.calltoActionRightDivIconMainDivPhone {
  font-weight: 600 !important;
  font-size: 22px !important;
}

/* VEHICLE BOX */

.vehicle-con-card {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  padding: 0 50px;
}

.vehicle-con-card:nth-child(even) {
  flex-direction: row-reverse;
  background: #c0c0c0;
}

.vehicle-con-card:nth-child(odd) {
  flex-direction: row;
  background: #fff;
}

/* VEHICLES CON FIRST DIV TARGET */
.vehicle-img-con {
  flex: 1;
}

.vehicle-img-con img {
  width: 100%;
  object-fit: contain !important;
}

.suv-img-con {
  margin-right: 2rem;
}

.suv-img {
  width: 110% !important;
}

/* VEHICLES CON SECOND DIV TARGET */
.vehicle-desc-con {
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: start;
  flex: 2;
}

.vehicle-desc-con span {
  font-weight: bold;
}

.vehicle-desc-con h5 {
  font-weight: bolder !important;
  color: black;
}

.vehicle-desc-con p {
  color: #444444;
}


/* About Box */
.AboutBoxColorWhite {
  color: #fff !important;
}

.AboutBoxColormainDiv {
  position: relative !important;
}

.AboutBoxColorLeftDivMain {
  background: #4b0082 !important;
  /* background: linear-gradient(to bottom, #336699, #66a3cc); */
  border-radius: 15px !important;
  box-shadow: rgba(38, 57, 77, 0.5) 0px 20px 30px -10px;
  padding: 10px;
}

.aboutBoxVideoDisplay {
  width: 100% !important;
  height: auto !important;
  border-radius: 15px !important;
}

/* Servise Box */
/* Choose Us */
.chooseUsIndigoColor {
  color: #fff !important;
}

/* Book a call */
.bookCallMainDiv {
  position: relative !important;
}

.bookCallSubDiv {
  background: #4b0082 !important;
  /* background: linear-gradient(to right, #336699, #66a3cc); */
  border-radius: 15px !important;
  box-shadow: rgba(38, 57, 77, 0.5) 0px 20px 30px -10px;
  padding: 10px;
}

.bookCallSubDivVideo {
  width: 100% !important;
  height: auto !important;
  border-radius: 15px !important;
}

/* AvailableBox */
.availableBoxShadows {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.availableBoxFadCars {
  width: 100% !important;
  height: auto !important;
  object-fit: cover !important;
}

/* Google Box Section */
.googleBoxMainPaqrenDiv {
  position: relative !important;
  margin-top: 2.5rem !important;
}

/* Review Box */
.whiteColor {
  color: #fff !important;
}

.reviewBoxItemStyle {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 50% !important;
  border: 2px solid indigo !important;
}

.reviewBoxitemTypes {
  /* margin-left: 0px !important; */
  color: indigo !important;
}

.slick-prev:before,
.slick-next:before {
  color: #00000063 !important;
}

.reviewBoxStarIcons {
  text-align: left !important;
  color: rgb(255, 209, 3) !important;
}

#mapmobilbedisplay {
  display: none;
}

.about-order-title {
  display: none;
}

.about-order-title-2 {
  display: block;
}

@media screen and (max-width:1600px) {
  .topBoxFields {
    margin-top: 5rem;
  }

  .popularCategoriesCard2 {
    height: 300px;
  }
}

@media screen and (max-width:800px) {
  .home-content-main {
    margin-top: 0;
  }

  .topBoxFields {
    margin-top: 2rem;
  }

  .topBoxSedanCar {
    width: 110%;
    height: 100%;
    position: static;
    top: 0;
  }

  .topboxClass {
    margin-top: -0.5rem;
    padding-top: 1.5rem;
  }

  .topBoxCar {
    margin-top: 0;
  }
}

@media screen and (max-width:767px) {
  .about-order-title {
    display: block;
  }

  .about-order-title-2 {
    display: none;
  }

  .about-box-first-con {
    height: 100%;
  }
}

@media screen and (max-width:769px) {
  .mobileboxdisplay {
    display: block !important;
  }

  .desktopboxdisplay {
    display: none !important;
  }

  .blogcardbox {
    width: 20rem;
    height: 35rem;
    background-color: #ffffff;
    margin-top: 2rem;
  }

  #mapmobilbedisplay {
    width: 100%;
    height: 15rem;
    background: #000000;
    display: block;
  }

  .columnbookrideform,
  .columnbookrideform1 {
    width: 100% !important;
    height: 100% !important;
  }

  .bookridetabbtnactive,
  .bookridetabbtn {
    padding: 24px 7px;
    font-size: 1rem;
  }

  .booking-form-panel {
    width: 100% !important;
  }

  .videobox-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-color: #00000063;
  }

  .aboutmorebtn1,
  .aboutmorebtn {
    width: auto;
    font-size: 0.6rem;
  }

  .videobox {
    width: 100%;
    height: auto;
  }

  .main {
    margin-top: 5rem;
  }

  .videooverlay h1,
  .welcome-taxi {
    font-size: 1rem;
  }

  .title {
    font-size: 50px;
    border-bottom: none !important;
  }

  .videooverlay p {
    font-size: 12px;
  }

  .service-box-con img {
    width: 80%;
  }
}

@media (max-width: 1024px) and (min-width:768px) {
  .booking-form-panel {
    width: 50%;
  }

  .columnbookrideform,
  .columnbookrideform1 {
    width: 50%;
  }

  .bookridetabbtn,
  .bookridetabbtnactive {
    font-size: 1.1rem;
  }

  .service-box-con img {
    width: 60%;
  }

  .vehicle-img-con {
    flex: 2;
  }

  .popularCategoriesCard2 {
    height: 400px;
  }
}

@media (max-width: 660px) and (min-width:275px) {
  .topBoxBlur {
    padding: 1rem 1rem;
  }

  .taxis-slider {
    width: 90%;
  }

  .googlebtn,
  .downloadbtn {
    margin-left: auto;
    margin-right: auto;
    width: 11rem !important;
    padding: 5px 15px;
  }

  .googlebtn span,
  .downloadbtn span {
    font-size: 12px;
  }

  .aboutmorebtn1,
  .aboutmorebtn {
    width: 10rem;
    font-size: 0.6rem;
  }

  .storeIconMainDiv {
    gap: 0.5rem;
  }

  .storeIconMainDiv p {
    font-size: 12px;
  }

  .storeIconMainDiv strong {
    font-size: 14px;
  }

  .storeIconMainDiv i {
    font-size: 17px !important;
  }

  .service-box-con p {
    width: 95%;
  }

  .service-box-con img {
    width: 100%;
  }

  .vehicle-con-card {
    padding-bottom: 1rem;
  }

  .vehicle-con-card:nth-child(even) {
    flex-direction: column !important;
  }

  .vehicle-con-card:nth-child(odd) {
    flex-direction: column !important;
  }

  .sedan-img-con,
  .suv-img-con,
  .maxi-img-con {
    margin-bottom: -5rem;
  }

  .popularCategoriesCard2 {
    height: 450px;
  }
}

@media screen and (max-width:500px) {
  .topBoxImage {
    background-attachment: unset;
  }

  .recaptcha {
    transform-origin: left top;
    -webkit-transform-origin: left top;
    scale: 0.7;
    transform-origin: 0 0;
  }
}

@media (max-width:468px) and (min-width:320px) {

  .googlebtn,
  .downloadbtn {
    width: 10rem;
    padding: 3px 10px;
  }

  .googlebtn span,
  .downloadbtn span {
    font-size: 10px;
  }

  .bookFormBoxbuttonDiv {
    gap: 0.3rem;
  }

  .storeIconMainDiv {
    width: 11rem;
    padding: 3px 15px;
  }

  .appleicon {
    font-size: 1.5rem;
  }

  #googleplaystor {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }

  .taxis-slider {
    width: 100%;
  }

  .slick-next {
    right: 0px !important;
  }

  .slick-prev {
    left: 0px !important;
  }

  .service-info-block {
    left: 140px;
  }

  .mapboxes {
    margin-top: 0;
  }
}

/* Default styles for all screen sizes */

/* Styles for smaller screens */
@media only screen and (max-width: 660px) {

  /* Modify the styles for the videoboxstoreIconMainDiv and its components */
  .videobox {
    margin-top: 5rem;
  }

  .videobox-image img {
    height: 50vh;
  }

  .videooverlay {
    /* position: absolute; */
    width: 100%;
    height: 100%;
    top: 0%;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    /* background-color: aqua; */
  }

  .videooverlay,
  .videooverlay1 {
    top: 6%;
  }

  .videooverlay h1,
  .welcome-taxi {
    font-size: 1.5rem;
  }

  .videooverlay h4 {
    font-size: 2rem;
  }



  /* Add additional modifications for other components to ensure proper mobile display */
  .booking-containermain.google-maps-container {
    width: 100%;
    height: 40vh;
  }

  /* Add more styles for other components as needed */
}

@media (max-width: 768px) {
  .welcome {
    display: none;
  }

  .welcome-taxi {
    padding-bottom: 50px;
  }

  .videooverlay h1,
  .welcome-taxi {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

  }
}

.mapboxbtn {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem !important;
  flex-wrap: wrap;
  justify-content: start;
  margin-bottom: 1rem;
}

.mapboxbtn * {
  flex-grow: 1 !important;
  /* width: 100% !important; */
}

.mapboxbtn button {
  width: 100% !important;
}

.mapboxs {
  margin-top: 13rem;
}

.mapboxes {
  margin-top: 20rem;
}

.ccc {
  margin-top: 20rem;
}