.parallax-con,
.parallax-con-estimate {
    background-size: cover;
    background-position: center;
    color: white;
    width: 100%;
    overflow: hidden;
}

.parallax-con {
    height: 75vh;
}

.parallax-con-estimate {
    height: 65vh;
    background-attachment: fixed;
}

.parallax-con>div,
.parallax-con-estimate>div {
    text-align: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* backdrop-filter: blur(5px); */
    padding: 10px;
}

.parallax-con>div {
    justify-content: start;
    padding-left: 3rem;
}

.parallax-con>div>div,
.parallax-con-estimate>div>div {
    width: 85%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    /* align-items: start; */
    /* background: pink; */
}

.parallax-con>div>div {
    width: 55%;
}

.parallax-con>div>div>h1,
.parallax-con-estimate>div>div>div>h1 {
    font-weight: 900;
    font-size: 45px;
    margin-bottom: 0;
}

.parallax-con>div>div>p,
.parallax-con-estimate>div>div>div>p {
    font-size: 18px;
}

@media screen and (max-width:1024px) {

    /* .parallax-con>div>div, */
    .parallax-con-estimate>div>div {
        width: 90%;
    }

    .parallax-con>div>div>h1,
    .parallax-con-estimate>div>div>div>h1 {
        font-weight: 900;
        font-size: 40px;
    }

    .parallax-con>div>div>p,
    .parallax-con-estimate>div>div>div>p {
        font-size: 16px;
    }
}

@media screen and (max-width:500px) {
    .parallax-con {
        background-attachment: unset !important;
        height: 70vh;
    }

    .parallax-con-estimate {
        background-attachment: unset !important;
        height: auto;
        padding: 1rem 0;
    }

    .parallax-con>div{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .parallax-con>div>div {
        width: 100%;
        justify-content: start;
        gap: 1rem;
    }

    .parallax-con-estimate>div>div {
        width: 95%;
        gap: 1rem;
        text-align: center;
    }

    .parallax-con>div>div>h1,
    .parallax-con-estimate>div>div>div>h1 {
        font-weight: 900;
        font-size: 33px;
    }

    .parallax-con>div>div>p,
    .parallax-con-estimate>div>div>div>p {
        font-size: 13px;
    }
}