/* .main-container{
    width: 100%;
    height: 2rem;
    background-color: #4b0082;
    display: flex;
    justify-content:space-around;
} */
/* .conerwhite{
    width: 12%;
    background-color: #ffffff;
    border-top-right-radius: 25px;
}
.conerindo{
    width: 80%;
    background-color: #4b0082;
    font-size: 12px;
} */
.typed-cursor {
    opacity: 0 !important;
}

.social {
    width: 12%;
    /* background-color: #ffffff; */
    border-top-left-radius: 25px;
}

.social ul {
    list-style: none;
    display: flex;
    /* color: #fff; */
    font-size: 1.4rem;
    padding-left: 0rem;
}

.social ul li {
    padding: 0px 5px;
}

a {
    color: #fff;
}

.header-details {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-transform: capitalize;
    gap: 100px;
}

.navbar {
    background-color: gainsboro;
    position: fixed;
    z-index: 6;
    /* height: 8rem; */
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sticky {
    top: 0;
}

.header-layout {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
}

.header-logo img {
    width: 100%;
    height: 8rem;
    object-fit: contain;
}

.header-logo {
    text-align: center;
    padding: 10px;
    width: 16%;
}

.header-menu {
    padding: 0px;
    width: 45%;
}

.header-menu ul {
    display: flex;
    list-style: none;
}

.header-menu ul li {
    font-size: 12px;
    padding: 0px 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2em;
    font-family: Harmonia Sans, sans-serif;
    margin-top: 15px
}

.header-menu ul li a {
    text-decoration: none;
    padding: 6px 0px;
    color: #4b0082;
    position: relative;
}

.header-menu ul li a.active {
    color: #000;
    text-decoration: none;
}

.header-menu ul li a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    transition: width 0.3s ease;
}

.header-menu ul li a:hover {
    color: #000;
}

.header-menu ul li a:hover::before {
    width: 100%;
}

.header-menu ul li a.active::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
}

.header-searchicon {
    /* padding-left:150px; */
    /* width: 35%; */
    display: flex;
    gap: 12px;
    align-items: center;

}

.social ul li {
    margin-bottom: -10px;
}

.booknowbtn {
    border: none;
    border-radius: 25px;
    width: 12rem;
    padding: 12px 20px;
    background: linear-gradient(to right, #4b0082 66%, #000000 100%);
    /* background: linear-gradient(to bottom, #336699, #66a3cc); */
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    color: #fff;
}

.booknowbtn:hover {
    background: linear-gradient(to right, #fff 66%, #4b0082 100%);
    /* background: linear-gradient(to top, #336699, #66a3cc); */
    color: #4b0082;
}

/*button*/
.wrapper1 {
    display: flex;
    justify-content: center;
}

.cta {
    display: flex;
    padding: 10px 15px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    color: #5075BA;
    background: #ffffff;
    transition: 1s;
    box-shadow: 6px 6px 0 black;
    transform: skewX(0deg);
}

.cta:focus {
    outline: none;
}

.cta:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 rgba(13, 110, 253, .25);
    font-size: 22px;
}

.cta span:nth-child(2) {
    transition: 0.5s;
    margin-right: 0px;
}

.cta:hover span:nth-child(2) {
    transition: 0.5s;
    margin-right: 13px;
}

span {
    transform: skewX(0deg)
}

span:nth-child(2) {
    width: 12px;
    margin-left: 20px;
    position: relative;
    top: 0%;
}

/**************SVG****************/

path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

.cta:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

/* SVG animations */

@keyframes color_anim {
    0% {
        fill: white;
    }

    50% {
        fill: #5075BA;
    }

    100% {
        fill: white;
    }
}

.mobilenavmain-container {
    display: none;
}

.nav-mobile-ulres {
    position: relative;
    background-color: #4b0082;
    width: 100%;
    list-style: none;
}

.nav-mobile-ulres li a {
    text-decoration: none;
    padding: 3px;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0.5rem 0rem;
}

.nav-mobile-ulres li a:hover,
.nav-mobile-ulres li a:active {
    color: #C0C0C0;
}

.nav-closebtn {
    border: none;
    background-color: transparent;
    color: #fff;
    float: right;
}

.nav-closebtn:hover {
    color: #242424;
}

/*mobile responsive code*/
@media screen and (max-width:769px) {
    .main-container {
        height: 1.5rem;
    }

    .social-media ul {
        font-size: 1.2rem;
    }

    .mobilenavmain-container {
        background-color: #c0c0c0;
        position: fixed;
        z-index: 6;
        height: 8rem;
        width: 100%;
        display: block;
    }

    .header-searchicon {
        padding: 8px 10px;
        width: 60%;
        margin-top: 5px;
    }

    .header-logo {
        width: 30%;
    }

    .meniicon {
        color: #fff;
        font-size: 3.2rem;
    }

    .navbar {
        display: none;
    }
}

@media screen and (max-width:490px) and (min-width:320px) {
    #text {
        width: 100%;
        color: #fff;
        text-align: center;
        text-decoration: underline;
        list-style: none;
        padding: 8px;
        font-size: 9px;
        font-weight: 700;
    }

    .social-media {
        display: none;
    }

    .header-logo img {
        object-fit: contain;
    }

    .meniicon {
        font-size: 2.5rem;
    }

    .header-layout {
        height: 4.5rem;
    }

    .mobilenavmain-container {
        height: 5rem;
        display: block;
    }

    .wrapper1 {
        display: none;
    }
}