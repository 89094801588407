/* .aboutPageCon {
    background-image: url('../../public/assets/sydney-at-night.jpg');
    background-size: 100% 100%;
    object-fit: contain;
    background-position: top !important;
    background-repeat: no-repeat;
    box-shadow: '0px 0px 10px 0px #000';
    color: #fff !important;
    height: 400px;
    padding: 0 !important;
    border-radius: 15px !important;
    overflow: hidden;
} */

/* .aboutBlur {
    backdrop-filter: blur(3px);
    width: 100%;
    border-radius: 15px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
} */

/* .about-img {
    background-image: url('../../public/assets/ABOUT-PIC.webp');
    background-size: cover;
    background-position: left;
    align-items: baseline;
    text-align: right;
    border-radius: 15px;
} 

.about-img>div {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: end;
    color: #fff;
    height: 100%;
    padding-left: 10rem;
} */

.about-div-con {
    display: flex;
    justify-content: right;
    width: 100%;
    gap: 2rem;
    padding: 2rem;
    margin: 0 auto;
    border-radius: 15px;
    background: #c0c0c0;
}

.about-div-con>div:nth-child(2) {
    flex: 2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 1;
}

.about-div-con>div:nth-child(2) h1 {
    font-weight: bolder;
    border-bottom: none !important;
}

.about-div-con>div:nth-child(1) {
    flex: 1;
}

.about-div-con>div:nth-child(1) img {
    width: 100%;
    height: auto;
}


.about-title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4em;
    width: fit-content;
    color: #fff;
    border-bottom: 5px solid gainsboro;
}

.aboutPageCon p {
    margin-top: 1rem;
    font-size: 20px;
    color: gainsboro;
}

.desktopdisplay {
    display: block;
}

.mobiledisplay {
    display: none;
}


@media screen and (max-width:768px) {
    .aboutPageCon {
        border-radius: 0px !important;
    }

    .about-div-con {
        flex-direction: column;
        align-content: center;
        height: auto;
    }

    .about-div-con>div:nth-child(2) {
        text-align: start;
        align-items: start;
        /* padding-right: 1rem; */
        gap: 2rem;
    }

    .about-div-con>div:nth-child(2) h1 {
        text-align: start;
    }

    .about-div-con>div:nth-child(1) img {
        width: 100%;
        height: auto;
    }
}