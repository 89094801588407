.links-con>div {
    margin-bottom: 1rem;
    display: flex;
    align-items: start;
    gap: 0.5rem;
}

.links-con div h5{
    margin-top: 0.4rem;
    display: none;
}

.links-con div h4 {
    margin: 0;
    color: black !important;
}

.links-con div a {
    color: blueviolet;
    margin: 0;
}

.links-con div a:hover {
    color: #892be2de;
}

.links-con div a:active {
    opacity: 0.7;
}