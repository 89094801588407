.policyContainerDiv {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 15px;
}

.policyContentDiv {
    text-align: left;
    padding: 1rem;
}

.h3 {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 0 !important;
}

.h3:hover,
.h3.active {
    color: indigo;
}