.footer-mainbox {
    background-color: #c0c0c0;
    border-top: 1px solid gainsboro;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-container:first-child {
    width: 100%;
}

.footer-subpart {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.download-title {
    margin-top: 1rem;
    color: white;
    font-size: 1rem;
    font-family: 'Poppins';
    text-align: left;
}

#input-flied {
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 20px;
    height: 3rem;
    margin-bottom: 10px;
}

.social-media-container {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.social-media-container li {
    list-style: none;
    height: 6rem;
    cursor: pointer;
    margin: 0;
}

.footerQuickLinksMain>div h4 {
    color: black;
}

h4 {
    color: #fff;
    margin-top: 1rem !important;
    font-size: 1.5rem !important;
    font-family: 'Poppins';
    text-align: left;
}

.footer-subpart-ul {
    /* margin-top: 1rem; */
    text-align: left;
}

.footer-subpart-ul li {
    list-style: none;
    font-size: 0.9rem;
    /* color: #252525; */
    font-weight: 900;
    margin: 0rem 0rem;
    cursor: pointer;
    padding: 10px 0px;
    /* color: black !important; */
    text-align: left;
}

.copyrightpara {
    padding: 0.5rem;
    color: white;
    justify-content: center;
    background-color: indigo;
    text-transform: uppercase;
    margin-bottom: 0rem;
    word-spacing: 0.2rem;
}

.copyrightpara a {
    color: #c0c0c0;
}

.copyrightpara a:hover {
    color: #029ED9;
}

.copyrightpara ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.copyrightpara ul li {
    padding: 5px 10px;
    color: #fff;
    font-size: 1.2rem;
}

.socialmediafooterul {
    display: flex;
    justify-content: space-evenly;
    margin: 1.5rem 0rem;
    font-size: 2rem;
    list-style: none;
}

.facebook,
.instagram,
.linkedin,
.youtube,
.telegram {
    color: black;
}

.facebook:hover,
.linkedin:hover {
    color: blue !important;
}

.instagram:hover {
    color: orangered !important;
}

.youtube:hover {
    color: red !important;
    border-radius: 50%;
}

.telegram:hover {
    color: #029ED9 !important;
}


/*  */
.footerMainDivColor {
    background: #c0c0c0;
}

.footerMainSubDiv {
    background: #c0c0c0;
    text-align: center;
}

.text-align-left {
    text-align: left;
}

.footerMainSubDivImg {
    width: 100%;
    height: 11rem;
    object-fit: cover;
    margin-left: -20px;
}

.footerMainIconsStyle {
    font-size: 1.3rem;
    text-decoration: none;
    color: black;
}

.footerQuickLinksMain {
    display: flex;
    background: #c0c0c0;
    justify-content: center;
    padding: 2rem 0;
}

.footerLinkStyle {
    color: #252525;
    text-decoration: none !important;
}

.socialMediaMainDiv {
    background: #c0c0c0;
    padding: 2rem 0;
}

.footerGoogleBuuton {
    font-size: 12px !important;
    margin-left: 5px !important;
}

.appStoreButtonDiv {
    text-align: left;
    margin-left: 3px;
}

.footerLoweIconDiv {
    display: flex !important;
    justify-content: center !important;
    list-style: none !important;
    padding: 0 !important;
}

.footerLoweIconsli {
    margin: 0 10px !important;
}

.socialMediaIconDivFas {
    text-align: left !important;
    font-size: 1rem !important;
    color: black;
    text-decoration: none;
}

/*  */
@media screen and (max-width:769px) {
    .social-media-container {
        gap: 1rem;
    }

    .facebook,
    .instagram,
    .linkedin,
    .youtube,
    .telegram {
        font-size: 1.5rem !important;
    }

    .socialMediaIconDivFas {
        width: 100%;
    }
    
    .socialMediaIconDivFas a {
        color: black;
        text-decoration: none;
    }
}

@media screen and (max-width:858px) {
    .footer-container {
        padding: 1rem;
    }

    .footer-subpart {
        width: 100%;
        margin-top: 1rem;
    }

    .copyrightpara {
        padding: 1rem;
    }
}

.download-title {
    @media (max-width:580px) and (min-width:320px) {
        margin-top: 3.5rem;
    }

    .copyrightpara {
        padding: 1rem 0rem 1rem;
        font-size: 12px;
        background-color: transparent;
    }

    .footermobiletitleh1 {
        /* margin: 1.5rem 0rem; */
        /* margin: 5rem !important;
        background: #000 !important; */
    }

    .footermobileicon {
        margin: 1.5rem 0rem;
        color: goldenrod;
        font-size: 2rem;
        font-weight: 900;
    }

    .footerdropdownmenu {
        position: relative;
        display: inline-block;
    }

    .footer-subpart-ul {
        display: none;
        position: relative;
        background-color: transparent;
        min-width: 160px;
        z-index: 1;
        top: 10%;
    }

    .footer-subpart-ul li {
        margin: 0.5rem 0.3rem;
        font-family: 'Poppins';
        color: black;
    }

    .footerLinkStyle {
        color: black !important;
    }

    .social-media-container {
        /* margin-top: 2.5rem;
        margin-bottom: 2.5rem !important; */
        /* justify-content: center !important;
        color: #fff;
        background: #000 !important; */
        /* margin-right: 50px; */
    }

    .social-media-container p {
        /* font-size: 10rem; */
        /* margin-left: 20px; */
        /* height: 0rem; */
        /* margin-bottom: 1.5rem; */
    }

    .footerdropdownmenu:hover .footer-subpart-ul {
        display: block;
    }

}