.viewCarDetails-main{
    overflow-y: scroll;
    height: 100vh !important;
}
.back-main{
    display: flex;
    align-items: center;
    height: 55px;
    border-bottom: 1px solid grey;
    padding-left:1rem
}
.details-text{
    text-align: left;
    padding: 0.5rem;
    padding-left: 1rem;
}
.available-car{
    background-color: #f8f6f2;
    font-size: 16px;
}
.fareEstimate{
    font-size: 20px;
    font-weight: bold;
    color: #4b0082;
    text-align: left;
    padding-left: 1rem;
}
.fare-estimate-text{
    text-align: left;
    padding-left: 1rem;
}
.select-service-button{
    width: 90%;
    height: 48px;
    color: #fff;
    background-color: #4b0082;
    border: none;
    border-radius: 3px;
    margin: auto;
    display: block;
    outline: none;
    cursor: pointer;
}