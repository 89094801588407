@font-face {
  font-family: 'Star7';
  src: url('/public/fonts/star7/STAR7.ttf') format('truetype');
  /* Add additional font formats if necessary */
}

/* HEADER MENU */
.menu-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around !important;
  align-items: center !important;
  background-color: #c0c0c0;
  height: auto;
  padding: 1em 0;
  padding-bottom: 1.9em;
  width: 100%;
}

.headermenuimageMain {
  object-fit: cover;
  margin: 0;
  width: 300px;
  height: 100px;
}

.container-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0.5rem;
  flex-basis: 200;
  margin-bottom: 1rem;
}

.contactContents {
  text-align: left !important;
  margin-left: 1rem !important;
}

/* NAVBAR MENU */
.navbarLogo {
  display: none;
}

.navbarLogo h4 {
  font-weight: 900;
}

.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #4b0082;
  color: #FFF;
  height: 60px;
  padding: 1em;
  margin-top: -1.7rem;
  position: relative;
}

.navbar-menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: flex-start;
  width: 70%;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  z-index: 99;
}

.followus-section {
  position: absolute;
  gap: 15;
  right: 25px;
  display: flex;
  align-items: center;
}

.follow-section-nav {
  gap: 15;
  right: 25px;
  display: flex;
  align-items: center;
}

.followus-section>* {
  margin: 0 0.5rem;
}

.navbar-menu>li {
  margin: 0 1rem;
  overflow: hidden;
}

.navbar-menu>li>a {
  cursor: pointer;
  text-decoration: none;
  color: white !important;
}


.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

#menu-toggle:checked+.menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked+.menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked+.menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media (max-width: 1024px) {
  .header-hidden-item {
    display: none;
  }
}

@media (max-width: 800px) {
  .top-nav {
    margin-top: 0;
  }

  .hideListItems {
    display: none !important;
  }

  .navbarLogo {
    display: block;
  }

  .menu-container {
    display: none;
  }

  .menu-button-container {
    display: flex;
  }

  .navbar-menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  #menu-toggle~.navbar-menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  #menu-toggle:checked~.navbar-menu li {
    border: 1px solid #4b0082;
    height: 2.5em;
    padding: 0.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }

  .navbar-menu>li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #4b0082;
  }

  .navbar-menu>li:not(:last-child) {
    border-bottom: 1px solid #4b0082;
  }
}

/* DROPDOWN */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.services-dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.sticky {
  position: fixed;
  top: 24px !important;
  width: 100%;
  z-index: 99 !important;
  transition: top 0.3s ease;
}

/* GLOWING BUTTON */
.booktaxi-btn {
  background: linear-gradient(to right, #4b0082 66%, #000000 100%);
  width: 12rem;
  ;
  border-radius: 25px;
  border: none;
  padding: 12px 20px;
  color: #FFFFFF;
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
  animation-name: flash;
  animation-duration: 1.3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.booktaxi-btn:hover {
  background: linear-gradient(to right, #fff 66%, #4b0082 100%);
  color: #4b0082;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.navbar-menu>li>a {
  cursor: pointer;
  text-decoration: none;
  color: white !important;
  position: relative;
}

.navbar-menu>li>a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: white;
  transition: width 0.3s;
}

.navbar-menu>li>a:hover::after {
  width: 100%;
}

.navbar-menu>li>a.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: white;
  transition: width 0.3s;
}